@import 'ext/fonts';
@import 'ext/normalize';
@import 'begin';
@import 'syntax';

$linkColor: rgb(22, 0, 146);
$mobileW: 768px;
$smallMobileW: 480px;
$bigScreenW: 1600px;
$leftWidth: 220px;

$gray: #999;
$lightGray: #eee;
$ctacolor: rgb(175, 175, 0);

*,*:before,*:after {
	box-sizing: border-box;
}

body {
	font-family: 'Nunito Sans', sans-serif;
	@include fluidType(16px, 19px);
	line-height: 1.55em;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: $linkColor;
	text-decoration: underline;
	transition: border-bottom 0.4s;
	&:hover {
		border-bottom: 0.5px solid $linkColor;
	}
}


h1, h2, h3 {
	line-height: 1.2em;
}

h1 {
	@include fluidType(36px, 48px);
}

h2 {
	font-weight: bold;
	@include fluidType(28px, 32px);
}

h4 {
	font-weight: normal;
	@include fluidType(18px, 20px);
}

figcaption {
	font-size: 10pt;
	line-height: 1.5em;
	margin-top: -1em;
}

header {
	// background-size: cover;
	padding: 120px 0;
	color: #fff;
	overflow: hidden;
	h2 {
		font-weight: normal;
		margin-top: 5px;
	}
	h1 {
		margin: 0;
	}

	.social {
		overflow: hidden;
		list-style-type: none;
		padding: 0;
		margin-top: 0;
		li {
			color: #fff;
			float: left;
			i {
				&:first-child::before {
					margin-left: 0;
				}
				@include fluidType(26px, 34px);
				transition: color .3s;
				&:hover {
					color: $gray;
				}
			}
		}
	}
}

.clearfix {
	clear: both;
}

//
// Basic Container Class
//
.container {
	margin:0 150px;
	max-width: 720px;

	div.highlighter-rouge {
		right: 150px;
	}

	@media screen and  (max-width: $mobileWidth) {
		margin:0 15px; 
		div.highlighter-rouge {
			right: 15px;
		}
		div.highlight {
			padding: 30px 15px;
		}
	}

	@media screen and (max-width: $tabWidth) and (min-width: $mobileWidth){
		margin:0 40px;	
		div.highlighter-rouge {
			right: 40px;
		}
		div.highlight {
			padding: 30px 40px;
		}
	}

	@media screen and (max-width: $desktopWidth) and (min-width: $tabWidth){
		margin:0 80px;
		div.highlighter-rouge {
			right: 80px;
		}
		div.highlight {
			padding: 30px 80px;
		}
	}

	@media screen and (min-width: $desktopWidth) and (max-width: 1280px) {
		margin:0 100px;
		div.highlighter-rouge {
			right: 100px;
		}
		div.highlight {
			padding: 30px 100px;
		}
	}
}

.post-title {
	color: #222;
	transition: color .3s ease;
	margin: .1em 0;
	&:hover {
		color: $gray;
		border-bottom: 0;
	}
}

.post-description {
	font-weight: normal;
	color: #ddd;

	margin-top:.5em;
	margin-bottom: 1.3em;
}

.post-date {
	color: $gray;
	.icon-calendar {
		margin-right: 6px;
	}
}

.post-tags {
	list-style-type: none;
	padding: 0;
	li {
		float: left;
		padding: 10px 20px;
		background-color: $lightGray;
		color: #000;
		margin: 5px;
		border-radius: 2px;
		@include fluidType(15px, 17px);
		&:first-child {
			margin-left: 0;
		}
	}
}

.posts {
	list-style-type: none;
	padding: 0;
	>li {
		margin-bottom: 30px;
		padding-bottom: 20px;
		&:not(:last-child) {
			border-bottom: 1px solid $lightGray;
		}
	}
}

.posts, .post-container {
	margin-top: 40px;
}

.post-meta {
	display: flex;
	justify-content: space-between;
	align-items: center;
	a:hover {
		border-bottom: none;
	}
}

.post-container {
	img {
		margin: 20px 0;
	}
}

.learn-software-containers {
	line-height: 1.5em;
	margin: 40px 0 100px 0;
}

.navbar {
	position: absolute;
	top: 0;
	right: 5vw;
	a {
		color: inherit;
	}
	ul {
		list-style-type: none;
		li {
			// background-color: #ff9f1c;
			border: 1px solid #fff;
			float: left;
			// margin: 5px;
			&:not(:first-child){
				border-left: 0;
			}
			padding: 10px 25px;
			// border-radius: 4px;
			@include fluidType(15px, 17px);
			transition: background-color .3s ease-in;
			&:hover {
				background-color: rgba(#fff, 0.15);
				cursor: pointer;
			}
		}
	}
}

.footer {
	line-height: 1.5em;
	margin: 40px 0 100px 0;
}